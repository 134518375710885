<template>
  <div id="templateMenu">
    <v-card>
      <v-card-title
        class="d-flex justify-space-between"
        primary-title
      >
        <h4>
          Nouveau modèle de document
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiBriefcase }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form v-model="valid">
          <label for="name">Nom du modèle</label>
          <v-text-field
            v-model="template.title"
            :rules="[v => !!v || 'Nom du modèle requis']"
          >
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-text>
        <p>
          Nouveau modèle à partir :
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn-toggle
          v-model="template.type"
          tile
          group
        >
          <v-btn
            text
            color="primary"
            value="pdf"
          >
            Document PDF
          </v-btn>
          <v-btn
            value="free"
            text
            color="primary"
          >
            Rédaction libre
          </v-btn>
        </v-btn-toggle>
      </v-card-actions>
      <v-card-text
        v-if="template.type ==='pdf'"
        class="d-flex"
      >
        <v-row>
          <v-col
            md="6"
            sm="12"
          >
            <v-file-input
              v-model="templateFile"
              label="Choisir le document"
            ></v-file-input>
          </v-col>
          <v-col
            md="6"
            sm="12"
          >
            <v-btn
              color="primary"
              text
              @click="importPdfTemplate"
            >
              Importer le fichier
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="templateFile.length > 0">
      <iframe
        :src="templateFile"
        frameborder="0"
      ></iframe>
    </v-card>
    <div
      v-if="template.type ==='free'"
      class="mt-4"
    >
      <TipTapEditor
        :template-title="template.title"
      />
    </div>
  </div>
</template>

<script>
import { mdiBriefcase } from '@mdi/js'
import TipTapEditor from '@/components/TipTapEditor.vue'

export default {
  metaInfo: {
    title: 'Nouveau modèle de document',
    titleTemplate: '%s - Jimbo',
  },
  components: {
    TipTapEditor,
  },
  data: () => ({
    template: {
      title: '',
      type: 'pdf',
    },
    valid: true,
    templateFile: [],
    icons: {
      mdiBriefcase,
    },
  }),
  methods: {
    importPdfTemplate() {
      // Upload ONE files
      const formData = new FormData()
      formData.append('file', this.templateFile, this.templateFile.name)
      this.$http.post(`${process.env.VUE_APP_API_URL}/upload`, formData)
        .then(response => {
          console.log(response)
          const organise = {
            directory: 'sources',
            files: [response.data],
          }
          this.$http.post(`${process.env.VUE_APP_API_URL}/directory/match-files`, organise)
            .then(res => {
              this.$emit('newContent', res.data)
              this.newFiles = []
            })
        })
        .catch(error => console.error(error))
    },
  },

}
</script>

<style>

</style>
