<template>
  <div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      content-class="variables-menu"
      absolute
      :close-on-click="false"
      :close-on-content-click="false"
      offset-y
    >
      <v-card>
        <v-card-text>
          <v-text-field
            v-model="search"
            dense
            label="Chercher une variable"
          >
          </v-text-field>
        </v-card-text>
        <v-card-text>
          <v-list>
            <v-list-group
              v-for="(variablesGroup, index) in variables"
              :key="index"
              :value="true"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ variablesGroup.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(variable, i) in variablesGroup.variables"
                :key="i"
                link
                @click="insertVariable(variable)"
              >
                <v-list-item-title v-text="variable.title"></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="showMenu =false"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mdiBriefcase } from '@mdi/js'

export default {
  props: {
    varMenu: {
      type: PointerEvent,
      default: new PointerEvent('click'),
    },
  },
  data() {
    return {
      icons: {
        mdiBriefcase,
      },
      search: '',
      showMenu: false,
      x: 0,
      y: 0,
      variables: [
        {
          title: 'Entreprise',
          name: 'businessContact',
          variables: [
            {
              title: 'Nom de l\'entreprise',
              value: '[[bcName]]',
            },
            {
              title: 'Siret de l\'entreprise',
              value: '[[bcSiret]]',
            },
          ],
        },
        {
          title: 'Apprenants',
          name: 'contact',
          variables: [
            {
              title: 'Nom de l\'apprenant',
              value: '[[contactLastName]]',
            },
            {
              title: 'Prénom de l\'apprenant',
              value: '[[contactFirstName]]',
            },
          ],
        },
        {
          title: 'Libre',
          name: 'free',
          variables: [
            {
              title: 'Date',
              value: '[[date]]',
            },
            {
              title: 'Nombre',
              value: '[[number]]',
            },
          ],
        },

      ],
    }
  },
  watch: {
    varMenu(val) {
      if (val) {
        this.show(val)
      }
    },
  },
  methods: {
    show(e) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    insertVariable({ value }) {
      this.$emit('variableInserted', value)
      this.showMenu = false
    },
  },
}
</script>

<style scoped>
.variables-menu{
    background-color: #ffffff;
}
</style>
